import { useEffect } from "react";
import GuestLayout from "@/Layouts/GuestLayout";
import { Head, Link, useForm } from "@inertiajs/react";
import logo from "@/Assets/img/HSBLCO-llc-01.png";
import bg from "@/Assets/img/bg.jpeg";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FormGroup, PrimaryButton } from "@/Components/index";

export default function Login({ canResetPassword }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset("password");
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();
        post(route("login"));
    };

    return (
        <GuestLayout>
            <Head title="Log in" />

            <section
                className="wrapper"
                style={{ backgroundImage: `url(${bg})` }}
            >
                <div className="custom_container">
                    <Form onSubmit={submit}>
                        <div className="wrapper_item">
                            {/* <img src={logo} alt="logo" /> */}
                            <h3>Login</h3>
                            <Row>
                                <FormGroup
                                    size="12"
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={data.email}
                                    placeholder="Enter Email Address"
                                    onValueChange={(e) =>
                                        setData("email", e.target.value)
                                    }
                                    message={errors.email}
                                    required="required"
                                />

                                <FormGroup
                                    size="12"
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={data.password}
                                    placeholder="Password"
                                    onValueChange={(e) =>
                                        setData("password", e.target.value)
                                    }
                                    message={errors.password}
                                    required="required"
                                />

                                <div className="input-group">
                                    <PrimaryButton
                                        type="submit"
                                        disabled={processing}
                                    >
                                        {processing ? "Loading..." : "Login"}
                                    </PrimaryButton>
                                </div>
                            </Row>
                        </div>
                    </Form>
                </div>
            </section>
        </GuestLayout>
    );
}
